export function getProductImpressions(offers, section, tipologia, subseccion1, title) {
    let products = []

    for (let [position, campaign] of offers.entries()) {
        // console.log('campaign', campaign)
        let product = {
            name: `${campaign.brand}_${campaign.model}`,
            id: `${campaign.campaignID}`,
            category: `${tipologia}`,
            list: `${title}`,
            brand: `${campaign.brand}`,
            dimension2: `${section}`,
            dimension3: `${subseccion1}`,
            position: position + 1,
            productType: campaign.productType,
        }
        products.push(product)
    }
    console.log(`**** DEBUG ECOMMERCE START ${section} ${title}`, offers, offers.length)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })

    setTimeout(
        () =>
            window.dataLayer.push({
                event: 'eec.impressionView',
                eventCategory: 'Ecommerce',
                eventAction: 'Product Impression',
                eventLabel: `${section}`,
                ecommerce: {
                    currencyCode: 'EUR',
                    impressions: products,
                },
            }),
        200
    )
    // console.log('**** DEBUG ECOMMERCE END', products)
    return products
}

export function clickProduct(product, title) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    console.log('*** DEBUG ECOMMERCE CLICK', product)
    window.dataLayer.push({
        event: 'eec.impressionClick',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Impression Click',
        eventLabel: title,
        ecommerce: {
            currencyCode: 'EUR',
            click: {
                actionField: { list: title },
                products: [product],
            },
        },
    })
}

export function checkoutStep1(campaign, seccion, subseccion1, tipologia, price, fuel) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    let result = {
        event: 'eec.checkout',
        eventCategory: 'Ecommerce',
        eventAction: 'Checkout Step 1',
        ecommerce: {
            currencyCode: 'EUR',
            checkout: {
                actionField: {
                    step: 1,
                },
                products: [
                    {
                        name: `${campaign.brand}_${campaign.model}`,
                        id: `${campaign.campaignID}`,
                        category: `${tipologia}`,
                        variant: `${fuel}`,
                        brand: `${campaign.brand}`,
                        dimension2: `${seccion}`,
                        dimension3: `${subseccion1}`,
                        quantity: 1,
                        price: price ? `${price}` : undefined,
                        productType: getProductType(campaign.productType),
                    },
                ],
            },
        },
    }
    console.log('*** DEBUG ECOMMERCE CHECKOUT STEP 1', result)
    setTimeout(() => {
        window.dataLayer.push(result)
    }, 200)
}

export function checkoutStep2(campaign, seccion, subseccion1, tipologia, price, fuel) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    let result = {
        event: 'eec.checkout',
        eventCategory: 'Ecommerce',
        eventAction: 'Checkout Step 2',
        ecommerce: {
            currencyCode: 'EUR',
            checkout: {
                actionField: {
                    step: 2,
                },
                products: [
                    {
                        name: `${campaign.brand}_${campaign.model}`,
                        id: `${campaign.campaignID}`,
                        category: `${tipologia}`,
                        variant: `${fuel}`,
                        brand: `${campaign.brand}`,
                        dimension2: `${seccion}`,
                        dimension3: `${subseccion1}`,
                        quantity: 1,
                        price: `${price}`,
                        productType: getProductType(campaign),
                    },
                ],
            },
        },
    }
    console.log('*** DEBUG ECOMMERCE CHECKOUT STEP 2')
    window.dataLayer.push(result)
}

export function orderConfirmation(campaign, seccion, subseccion1, tipologia, price, fuel) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    let result = {
        event: 'eec.purchase',
        eventCategory: 'Ecommerce',
        eventAction: 'Purchase',
        ecommerce: {
            currencyCode: 'EUR',
            purchase: {
                actionField: {
                    id: `${campaign.campaignID}-${Date.now()}`,
                    revenue: `${price}`,
                },
                products: [
                    {
                        name: `${campaign.brand}_${campaign.model}`,
                        id: `${campaign.campaignID}`,
                        category: `${tipologia}`,
                        variant: `${fuel}`,
                        brand: `${campaign.brand}`,
                        dimension2: `${seccion}`,
                        dimension3: `${subseccion1}`,
                        quantity: 1,
                        price: `${price}`,
                        productType: getProductType(campaign),
                    },
                ],
            },
        },
    }
    // console.log('*** DEBUG ECOMMERCE PURCHASE', result)
    window.dataLayer.push(result)
}

function getProductType(campaign) {
    if (campaign?.productType) return campaign.productType
    return 'upfront'
}
