import slugify from 'slugify'
import fechas from 'data/fechavalidez.json' assert { type: 'json' }

export function reactTextContent(element) {
    if (!element) {
        return ''
    }
    if (typeof element === 'string') {
        return element
    }
    const children = element.props && element.props.children
    if (children instanceof Array) {
        return children.map(reactTextContent).join('\n')
    }
    return reactTextContent(children)
}

export function getLastMonthDay() {
  return fechas.main
  let today = new Date()
    let addition = 1
    if (today.getDate() >= 25) {
        addition = 2
    }
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + addition, 0)
    return lastDayOfMonth.toLocaleDateString('es-ES')
}
export function getPrettyFuel(campaign) {
    if (campaign?.motorType === 'E' && parseInt(campaign?.tankCapacity) === 0) return 'Eléctrico'
    if (campaign?.motorType === 'E' && parseInt(campaign?.tankCapacity) > 0) return 'Híbrido'

    if (campaign?.fuelPrimary === 'G' && campaign?.fuelSecondary === 'U') return 'GLP/Gasolina'

    if (campaign?.hybridlite) return 'Microhíbrido'
    if (campaign?.hybrid) return 'Híbrido'
    if (campaign?.pluginhybrid) return 'Híbr.Enchufable'
    if (campaign?.electric) return 'Eléctrico'
    if (campaign?.fuel === 'D') return 'Diesel'
    if (campaign?.fuelPrimary === 'E' && campaign?.fuelSecondary === 'U') return 'Híbrido'
    if (campaign?.fuelPrimary === 'E' && campaign?.fuel !== 'E') return 'Eléctrico'
    if (campaign?.fuel === 'E') return 'Eléctrico'
    if (campaign?.fuel === 'P') return `Gasolina`
    return '-'
}

export function getPrettyGear(campaign) {
    if (campaign?.gear === 0) return 'Automático'
    if (campaign?.gear > 7) return 'Automático'
    if (!campaign?.gear) return ``
    return `Manual`
}

export function getAnalyticsFuel(campaign) {
    if (campaign?.fuel === 'D') return 'diesel'
    if (campaign?.hybridlite) return 'hibrido'
    if (campaign?.hybrid) return 'hibrido'
    if (campaign?.pluginhybrid) return 'hibrido enchufable'
    if (campaign?.fuelPrimary === 'E') return 'electrico'
    if (campaign?.electric) return 'electrico'
    if (campaign?.fuel === 'P') return `gasolina`
    if (campaign?.fuel === 'E') return 'electrico'
    return null
}

export function getAnalyticsGear(campaign) {
    if (campaign?.gear === 0) return 'automatico'
    if (campaign?.gear > 7) return 'automatico'
    if (!campaign?.gear) return ``
    return `manual:${campaign?.gear}`
}

export function getDefaultProduct(campaign) {
    let defaultProduct = campaign?.defaultProduct
        ? campaign.defaultProduct
        : campaign?.productType &&
          campaign.productType in campaign.quotes &&
          campaign?.quotes[campaign.productType] !== null
        ? campaign.productType
        : '870'
    if (campaign?.quotes && !campaign?.quotes[campaign.productType]) {
        if (campaign?.quotes['870']) defaultProduct = '870'
    }
    return defaultProduct
}

export function getDefaultTerm(campaign, tipologia) {
    const defaultProduct = getDefaultProduct(campaign)
    const fallbackTerm =
        campaign?.productType === 'upfront' || (campaign?.productType === undefined && tipologia === 'particulares')
            ? '72'
            : '60'
    // const defaultTerm = campaign?.defaultTerm ? campaign.defaultTerm : campaign?.cheapest?.[defaultProduct] ? campaign?.cheapest?.[defaultProduct].term : "60"
    const defaultTerm = campaign?.defaultTerm ? campaign.defaultTerm : fallbackTerm
    // Legacy reasons for replace
    return defaultTerm.toString().replace('_', '')
}

export function getDefaultMileage(campaign) {
    const defaultProduct = getDefaultProduct(campaign)
    const defaultMileage = campaign?.defaultMileage
        ? campaign.defaultMileage
        : campaign?.cheapest?.[defaultProduct]
        ? campaign?.cheapest?.[defaultProduct].mileage
        : '10000'
    // Legacy reasons for replace
    return defaultMileage.toString().replace('_', '')
}

export function getVAT() {
    return 1.21
}

export function roundNumber(number) {
    return Math.round(number).toLocaleString('es-ES')
}

export function getCampaignURL(campaign, tipologia) {
    let description = `${slugify(campaign.brand)}-${slugify(campaign.model)}${tipologia ? '-' + tipologia : ''}`
    let uri = ''
    if (campaign.productType === 'flexible' || campaign.productType === 'flexible10') {
        uri = `/ofertas/${description}/${campaign.campaignID}/flexible/`.toLowerCase()
    } else if (campaign.productType === 'ucl') {
        uri = `/ofertas/${description}/${campaign.campaignID}/seminuevo/`.toLowerCase()
    } else {
        uri = `/ofertas/${description}/${campaign.campaignID}/`.toLowerCase()
    }
    return uri
}

export function flattenProduct(product, sort) {
    let results = []
    if (!product) return results
    const terms = Object.keys(product)

    for (let term of terms) {
        const mileages = Object.keys(product[term])
        for (let mileage of mileages) {
            const values = product[term][mileage]
            results.push({
                ...values,
                mileage: parseInt(mileage),
                term: parseInt(term),
                id: `${term}-${mileage}`,
            })
        }
    }
    if (sort) {
        results.sort((a, b) => {
            if (a.monthlyQuote < b.monthlyQuote) return -1
            if (a.monthlyQuote > b.monthlyQuote) return 1
            return 0
        })
    }
    return results
}
