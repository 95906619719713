"use client"

import { getProductImpressions } from 'utils/ecommerce'
import { useEffect } from 'react'

export default function DestacadosProductImpressions({ setCampaignsCallback, campaigns, section, tipologia, subseccion1, title }) {

  useEffect(() => {
    if (setCampaignsCallback) setCampaignsCallback(campaigns)
  }, [])

  useEffect(() => {
    getProductImpressions(campaigns, section, tipologia, subseccion1, title)
  }, [campaigns, section, tipologia, subseccion1, title])


  return <></>
}
